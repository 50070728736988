import styled from '@emotion/styled';
import { Box, LinearProgress, Stack, Typography, FormControlLabel, IconButton, Tooltip, CircularProgress, FormControl, InputLabel, Select, MenuItem, Alert } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { TokenContext } from '../context/TokenContext';
import StepHeader from './StepHeader';
import { IOSSwitch } from './Switch';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import EditIcon from '@mui/icons-material/Edit';
import { LoadingButton } from '@mui/lab';
import { afasPoster } from '../hooks/useAfasDataMutation';
import useAfasData from '../hooks/useAfasData';
import PreviewTemplate from './management/PreviewTemplate';

const TextFieldLabel = styled(Typography)({
  fontSize: '1rem',
  fontWeight: 500,
});

const Step2 = ({ config, vacancy, sx, langSelector, setLangSelector }) => {
  const token = useContext(TokenContext);
  const { t } = useTranslation('common');

  const params = new URLSearchParams({
    language: langSelector,
  }).toString();

  const { data: masterTemplates, isLoading: masterTemplatesIsLoading, mutate: masterTemplatesMutate } = useAfasData(
    `/getmastertemplatesbyvacancy/${langSelector}/${vacancy.vacancyId}`
  );

  async function streamGpt(key, setter) {
    const stream = await fetch(`${process.env.REACT_APP_GPT_STREAM_URL}?${params}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ token, key, vacancyId: vacancy.vacancyId }),
    });
    const streamResponse = stream.body;
    const reader = streamResponse.getReader();

    let done = false;
    let content = '';
    const decoder = new TextDecoder();

    while (!done) {
      const { value, done: doneReading } = await reader.read();
      done = doneReading;
      const chunkValue = decoder.decode(value);
      if (value) {
        content = content + chunkValue;
      }
      setter(content);
    }
  }

  /**
   * Post a master template to the endpoint, given a vacancyId, memoNum and body
   * @param {string} vacancyId - The id of the vacancy
   * @param {number} memoNum - The number of the memo to post
   * @param {object} body - The body of the post request
   * @returns {Promise<object>} - The response of the post request
   */
  async function postMasterTemplate(vacancyId, memoNum, body) {
    const arg = { token, body };
    let postData = await afasPoster(`/setmastertemplate/${langSelector}/${vacancyId}/${memoNum}/${selectedTemplate}`, { arg });
    setUpdatingBtn(0);
    return postData;
  }

  /**
   * Update a master template, given a memo number and a new content string
   * @param {number} memoNum - The number of the memo to update
   * @param {string} memoContent - The new content of the memo
   * @returns {Promise<void>} - Resolves when the master template is updated
   * @throws {Error} - If the master template update fails
   */
  async function updateMasterTemplate(memoNum, memoContent) {
    if (!vacancy.vacancyId) return;
    const vacancyId = vacancy.vacancyId;
    const body = {
      content: memoContent,
    };
    setUpdatingBtn(memoNum);
    try {
      await postMasterTemplate(vacancyId, memoNum, body);
      setSuccessUpdatingBtn(memoNum);
      masterTemplatesMutate();
      setTimeout(() => {
        setSuccessUpdatingBtn(0);
      }, 3000);
    } catch (error) {
      throw error;
    }
  }

  async function handleChange(e, setter, key, setLoader) {
    if (e.target.checked) {
      setLoader(true);
      await streamGpt(key, setter);
      setLoader(false);
    } else {
      setLoader(false);
    }
  }

  const [showUpdateBtn, setShowUpdateBtn] = useState(0);
  const [updatingBtn, setUpdatingBtn] = useState(0);
  const [successUpdatingBtn, setSuccessUpdatingBtn] = useState(0);

  const [introductie, setIntroductie] = useState('');
  const [introductieLoading, setIntroductieLoading] = useState(false);
  const [overvaca, setOvervaca] = useState('');
  const [overvacaLoading, setOvervacaLoading] = useState(false);
  const [werkzaamheden, setWerkzaamheden] = useState('');
  const [werkzaamhedenLoading, setWerkzaamhedenLoading] = useState(false);
  const [overons, setOverons] = useState('');
  const [overonsLoading, setOveronsLoading] = useState(false);
  const [introductieEn, setIntroductieEn] = useState('');
  const [introductieLoadingEn, setIntroductieLoadingEn] = useState(false);
  const [overvacaEn, setOvervacaEn] = useState('');
  const [overvacaLoadingEn, setOvervacaLoadingEn] = useState(false);
  const [werkzaamhedenEn, setWerkzaamhedenEn] = useState('');
  const [werkzaamhedenLoadingEn, setWerkzaamhedenLoadingEn] = useState(false);
  const [overonsEn, setOveronsEn] = useState('');
  const [overonsLoadingEn, setOveronsLoadingEn] = useState(false);

  useEffect(() => {
    if (!introductie && vacancy.Introductie) {
      setIntroductie(vacancy.Introductie);
    }
    if (!overvaca && vacancy.Over_de_vacature) {
      setOvervaca(vacancy.Over_de_vacature);
    }
    if (!werkzaamheden && vacancy.Jouw_werkzaamheden) {
      setWerkzaamheden(vacancy.Jouw_werkzaamheden);
    }
    if (!overons && vacancy.Over_ons) {
      setOverons(vacancy.Over_ons);
    }
    if (!introductieEn && vacancy.Introductie_en) {
      setIntroductieEn(vacancy.Introductie_en);
    }
    if (!overvacaEn && vacancy.Over_de_vacature_en) {
      setOvervacaEn(vacancy.Over_de_vacature_en);
    }
    if (!werkzaamhedenEn && vacancy.Jouw_werkzaamheden_en) {
      setWerkzaamhedenEn(vacancy.Jouw_werkzaamheden_en);
    }
    if (!overonsEn && vacancy.Over_ons_en) {
      setOveronsEn(vacancy.Over_ons_en);
    }
  }, [
    vacancy,
    introductie,
    overvaca,
    werkzaamheden,
    overons,
    introductieEn,
    overvacaEn,
    werkzaamhedenEn,
    overonsEn,
  ]);

  const nlfields = [
    {
      label: config?.Heading1 ? config?.Heading1 : t('labels.introductie'),
      value: introductie,
      key: 'introductie',
      setter: setIntroductie,
      onChange: (e) => handleChange(e, setIntroductie, 'introductie', setIntroductieLoading),
      loading: introductieLoading,
    },
    {
      label: config?.Heading2 ? config?.Heading2 : t('labels.overvaca'),
      value: overvaca,
      key: 'over_de_vacature',
      setter: setOvervaca,
      onChange: (e) => handleChange(e, setOvervaca, 'overvacature', setOvervacaLoading),
      loading: overvacaLoading,
    },
    {
      label: config?.Heading3 ? config?.Heading3 : t('labels.werkzaamheden'),
      value: werkzaamheden,
      key: 'jouw_werkzaamheden',
      setter: setWerkzaamheden,
      onChange: (e) => handleChange(e, setWerkzaamheden, 'werkzaamheden', setWerkzaamhedenLoading),
      loading: werkzaamhedenLoading,
    },
    {
      label: config?.Heading4 ? config?.Heading4 : t('labels.overons'),
      value: overons,
      key: 'over_ons',
      setter: setOverons,
      onChange: (e) => handleChange(e, setOverons, 'overons', setOveronsLoading),
      loading: overonsLoading,
    },
    {
      label: config?.Heading1 ? config?.Heading1 : t('labels.introductie'),
      value: introductieEn,
      key: 'introductie_en',
      setter: setIntroductieEn,
      onChange: (e) => handleChange(e, setIntroductieEn, 'introductie', setIntroductieLoadingEn),
      loading: introductieLoadingEn,
    },
    {
      label: config?.Heading2 ? config?.Heading2 : t('labels.overvaca'),
      value: overvacaEn,
      key: 'over_de_vacature_en',
      setter: setOvervacaEn,
      onChange: (e) => handleChange(e, setOvervacaEn, 'overvacature', setOvervacaLoadingEn),
      loading: overvacaLoadingEn,
    },
    {
      label: config?.Heading3 ? config?.Heading3 : t('labels.werkzaamheden'),
      value: werkzaamhedenEn,
      key: 'jouw_werkzaamheden_en',
      setter: setWerkzaamhedenEn,
      onChange: (e) => handleChange(e, setWerkzaamhedenEn, 'werkzaamheden', setWerkzaamhedenLoadingEn),
      loading: werkzaamhedenLoadingEn,
    },
    {
      label: config?.Heading4 ? config?.Heading4 : t('labels.overons'),
      value: overonsEn,
      key: 'over_ons_en',
      setter: setOveronsEn,
      onChange: (e) => handleChange(e, setOveronsEn, 'overons', setOveronsLoadingEn),
      loading: overonsLoadingEn,
    },
  ];

  const quillModules = {
    toolbar: [
      [{ header: [1, 2, 3, false] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['link'],
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    },
  };

  const quillFormats = ['header', 'bold', 'italic', 'underline', 'strike', 'list', 'bullet', 'link'];

  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const handleSelectedTemplateChange = (event) => {
    setSelectedTemplate(event.target.value);
  };

  useEffect(() => {
    if (masterTemplates && masterTemplates.length === 0) {
      setSelectedTemplate(null);
    }
  }, [masterTemplates])

  return (
    <>
      <Box {...sx}>
        <StepHeader
          description={t('steps.description.vacaturetekst')}
          title={t('steps.titles.step_2')}
          stepNumber={2}
        />
        <Box mb={4} display="flex" alignItems="center">
          <img
            src="https://flagcdn.com/w40/nl.png"
            alt="Dutch flag"
            style={{ width: '24px', height: '16px', marginRight: '0px' }}
          />
          <FormControlLabel
            control={
              <IOSSwitch
                sx={{ ml: 2 }}
                onChange={() => {
                  setLangSelector(langSelector === 'nl' ? 'en' : 'nl');
                  // i18n.changeLanguage(langSelector === 'nl' ? 'en' : 'nl');
                }}
              />
            }
            // label={t('labels.chatgpt')}
            label={''}
            labelPlacement="start"
            variant="standard"
            sx={{ mr: 0 }}
          />
          <img
            src="https://flagcdn.com/w40/gb.png"
            alt="English flag"
            style={{ width: '24px', height: '16px', marginLeft: '24px' }}
          />
        </Box>
        <Box my={2}>
          {masterTemplatesIsLoading && <CircularProgress />}
          {!masterTemplatesIsLoading && masterTemplates && masterTemplates.length > 0 && (
            <>
              <Stack direction={'row'} spacing={2} alignItems={'center'} useFlexGap>
                <FormControl fullWidth sx={{
                  maxWidth: '400px'
                }}>
                  <InputLabel id="demo-simple-select-label">{t('management.mastertemplate.use')}</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectedTemplate}
                    label={t('management.mastertemplate.use')}
                    onChange={handleSelectedTemplateChange}
                  >
                    {masterTemplates.map((template) => (
                      <MenuItem value={template.Index}>{template.Title}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {selectedTemplate !== null && (
                  <>
                    <PreviewTemplate fields={nlfields} lang={langSelector} masterTemplate={masterTemplates.find((template) => template.Index === selectedTemplate)} />
                  </>
                )}
              </Stack>
            </>
          )}

        </Box>
        {nlfields.map((field, index) => (
          <Box mt={4} key={index}>
            {field.loading && <LinearProgress sx={{ marginBottom: '1em' }} />}
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              mt={2}
              sx={{
                width: '80%',
                display:
                  (langSelector === 'en' && !field.key.includes('_en')) ||
                    (langSelector !== 'en' && field.key.includes('_en'))
                    ? 'none'
                    : 'block',
              }}
            >
              <TextFieldLabel variant="body2" my={2}>
                {field.label}
              </TextFieldLabel>
              {showUpdateBtn === index + 1 && (
                <Stack direction={'row'} spacing={2} useFlexGap justifyContent={'flex-start'} alignItems={'center'}>
                  <LoadingButton
                    loading={updatingBtn === index + 1}
                    onClick={() => {
                      const memoNum = (index + 1) > 4 ? (index + 1 - 4) : index + 1;
                      updateMasterTemplate(memoNum, field.value);
                    }}
                    variant="contained"
                    size="small"
                    loadingPosition="start"
                    startIcon={<EditIcon />}
                  >
                    {t('management.updatemaster')}
                  </LoadingButton>
                  {successUpdatingBtn === index + 1 && (
                    <Box mr={4}>
                      <Alert severity="success">
                        {t('management.mastertemplate.successsaved')}
                      </Alert>
                    </Box>
                  )}
                </Stack>
              )}
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              mt={2}
              sx={{
                display:
                  (langSelector === 'en' && !field.key.includes('_en')) ||
                    (langSelector !== 'en' && field.key.includes('_en'))
                    ? 'none'
                    : 'flex',
              }}
            >
              <ReactQuill
                placeholder={field.label}
                label={field.label}
                value={field.value}
                modules={quillModules}
                formats={quillFormats}
                theme="snow"
                onChange={field.setter}
                onBlur={() => setShowUpdateBtn(0)}
                onFocus={() => {
                  setTimeout(function () {
                    setShowUpdateBtn(selectedTemplate !== null ? Number(index) + 1 : 0);
                  }, 50);
                }}
              />
              <input type="hidden" name={field.key} value={field.value} />
              <Stack direction="row">
                <Tooltip title={t('labels.chatgpttooltip')}>
                  <IconButton>
                    <HelpOutlineIcon />
                  </IconButton>
                </Tooltip>
                <FormControlLabel
                  control={<IOSSwitch sx={{ ml: 2 }} onChange={field.onChange} />}
                  label={t('labels.chatgpt')}
                  labelPlacement="start"
                  variant="standard"
                  sx={{ mr: 0 }}
                />
              </Stack>
            </Stack>
          </Box>
        ))}
      </Box>
    </>
  );
};

export default Step2;
