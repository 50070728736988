import { Accordion, AccordionDetails, AccordionSummary, Avatar, Badge, Box, Stack, TextField, Typography } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditIcon from '@mui/icons-material/Edit';
import ReactQuill from "react-quill";
import { useTranslation } from "react-i18next";
import DeleteSubFunctionButton from "./DeleteSubFunctionButton";
/**
 * @typedef {Object} Subfunction
 * @property {int} Index - The employer id
 * @property {string} Title - Title for the subfunction
 * @property {string} Memo1
 * @property {string} Memo2
 * @property {string} Memo3
 * @property {string} Memo4
*/

const SubFunctionManagement = (properties) => {
  const { t } = useTranslation('common');
  function removeSubFunction(index) {
    setSubFunctions(oldval => oldval.filter((sub, i) => i !== index));
  }

  /** @type {Subfunction} */
  const subFunc = properties.subFunc;
  const setSubFunctions = properties.setSubFunctions;
  const config = properties.config;

  const quillModules = {
    toolbar: [
      [{ header: [1, 2, 3, false] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['link'],
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    },
  };

  const quillFormats = ['header', 'bold', 'italic', 'underline', 'strike', 'list', 'bullet', 'link'];
  const labels = [
    t('labels.introductie'),
    t('labels.overvaca'),
    t('labels.werkzaamheden'),
    t('labels.overons'),
  ]

  return (
    <>
      <Box mt={2}>
        <Accordion sx={{
          backgroundColor: 'transparent',
          width: '100%',
        }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={(theme) => ({
              boxShadow: theme.shadows[1],
              borderRadius: '10px',
              backgroundColor: 'white',
            })}
          >
            <Stack
              direction={'row'}
              justifyContent={'flex-start'}
              gap={4}
              alignItems={'center'}
              useFlexGap={true}
              sx={{
                width: '100%',
                paddingRight: '2rem'
              }}
            >
              <Avatar sx={(theme) => ({ bgcolor: theme.vars.palette.primary.main })}>
                <EditIcon sx={(theme) => ({ color: theme.vars.palette.primary.contrastText })} />
              </Avatar>
              <Badge
                showZero
                badgeContent={''}
                size="small"
                color={subFunc.Title && subFunc.Memo1 && subFunc.Memo2 && subFunc.Memo3 && subFunc.Memo4 ? 'success' : 'warning'}>
                <Typography variant="h5">{subFunc.Title}</Typography>
              </Badge>
              <DeleteSubFunctionButton removeSubFunction={removeSubFunction} subFunc={subFunc} />
            </Stack>
          </AccordionSummary>
          <AccordionDetails sx={(theme) => ({
            backgroundColor: 'white',
            marginLeft: '2rem',
            boxShadow: theme.shadows[1],
            borderRadius: '10px',
            borderTop: 'unset',
          })}>
            <Box p={4} maxWidth='800px'>
              <Box mb={2}>
                <TextField
                  fullWidth
                  label={t('management.mastertemplate.fields.subfunctiontitle')}
                  variant="outlined"
                  required={true}
                  value={subFunc.Title}
                  onChange={(e) => {
                    setSubFunctions(oldval => {
                      const newval = oldval.map((sub) => {
                        if (sub.Index === subFunc.Index) {
                          return { ...sub, Title: e.target.value };
                        }
                        return sub;
                      });
                      return newval;
                    });
                  }} />
              </Box>
              {['Memo1', 'Memo2', 'Memo3', 'Memo4'].map((memo, i) => (
                <Box mb={2}>
                  <Typography mb={1} sx={{ display: 'block', fontSize: '.9rem' }} variant="caption">{config[`Heading${i + 1}`] ? (config[`Heading${i + 1}`]) : (labels[i])}</Typography>
                  <ReactQuill
                    placeholder={memo}
                    label={memo}
                    value={subFunc[memo]}
                    modules={quillModules}
                    formats={quillFormats}
                    style={{
                      width: '100%'
                    }}
                    onChange={(val) => {
                      setSubFunctions(oldval => {
                        const newval = oldval.map((sub) => {
                          if (sub.Index === subFunc.Index) {
                            return { ...sub, [memo]: val };
                          }
                          return sub;
                        });
                        return newval;
                      });
                    }}
                  />
                </Box>
              ))}
            </Box>
          </AccordionDetails>
        </Accordion>
      </Box>
    </>);
}

export default SubFunctionManagement;