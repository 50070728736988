import { Card, CardContent, Typography, Box, Grid, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { useContext } from 'react';
import { Add } from '@mui/icons-material';
import { TokenContext } from '../../context/TokenContext';
import { InSiteContext } from '../../context/InSiteContext';
import ConnectSource from '../../components/management/ConnectSource';
import { DatahubContext } from '../../context/DatahubContext';
import useDatahubData from '../../hooks/useDatahubData';
import ApplyUrl from '../../components/management/ApplyUrl';

export const TextFieldLabel = styled(Typography)({
  fontSize: '1rem',
  fontWeight: 500,
});

const Sources = () => {
  const token = useContext(TokenContext);
  const { environmentId } = useContext(InSiteContext);

  const { t } = useTranslation('common');

  const { workspace } = useContext(DatahubContext);

  const { data: dataSources, mutate: setPreferred } = useDatahubData(
    workspace ? '/preferred-data-source' : null,
    {
      teamId: workspace?.id,
    },
  );
  const { data: feeds, mutate: setFeeds } = useDatahubData(
    dataSources && dataSources?.find((ds) => ds.dataProvider === 'GLADIOR_ATS') ? '/ats-feeds' : null,
    {
      dataSourceId: dataSources?.find((ds) => ds.dataProvider === 'GLADIOR_ATS')?.id,
    },
  );
  const { data: allDataSources } = useDatahubData(workspace ? '/data-sources' : null, {
    teamId: workspace?.id,
  });

  const sources = [
    {
      name: 'Google Ads',
      connected: false,
      connected_with: '',
      key: 'GOOGLE_ADS',
      image: '/images/google_ads.png',
      connect_url: '//',
      urlPath: 'google-ads',
    },
    // {
    //   name: 'Facebook Ads',
    //   connected: false,
    //   connected_with: '',
    //   key: 'FACEBOOK_ADS',
    //   image: '/images/facebook_ads.png',
    //   connect_url: '//',
    // },
    // {
    //   name: 'LinkedIn',
    //   connected: false,
    //   connected_with: '',
    //   key: 'LINKEDIN',
    //   image: '/images/linkedin.png',
    //   connect_url: '//',
    // },
    {
      name: 'Indeed',
      connected: true,
      connected_with: 'voorbeeld@voorbeeld.nl',
      key: 'INDEED_ATS',
      image: '/images/indeed.png',
      connect_url: '//',
    },
    {
      name: 'Microsoft Teams',
      connected: true,
      connected_with: 'voorbeeld@voorbeeld.nl',
      key: 'MICROSOFT_TEAMS_CHAT',
      image: '/images/teams.png',
      connect_url: '//',
      communication: true,
    },
    {
      name: 'Microsoft Outlook',
      connected: true,
      connected_with: 'voorbeeld@voorbeeld.nl',
      key: 'MAIL',
      image: '/images/outlook.png',
      connect_url: '//',
      communication: true,
    },
  ];

  return (
    <>
      <Card
        sx={(theme) => ({
          borderRadius: '10px',
          minHeight: '600px',
          boxShadow: theme.shadows[1],
          backgroundColor: 'white',
        })}
      >
        <CardContent
          sx={{
            padding: '3.5rem 4.5rem',
          }}
        >
          <Box mb={4}>
            <Box
              sx={{
                display: 'flex',
                gap: '1rem',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography variant="h3">{t('management.connectchannels')}</Typography>{' '}
              <Button
                variant="contained"
                endIcon={<Add></Add>}
                onClick={async () => {
                  window
                    .open(
                      `${process.env.REACT_APP_DATA_HUB_UI_URL}/workspace/${workspace?.id}/data-sources/d/${dataSources.find((d) => d.dataProvider === 'GLADIOR_ATS')?.id
                      }?&afasToken=${token}&environment=${environmentId}`,
                      '_blank',
                    )
                    .focus();
                }}
              >
                {t('management.new_channel')}
              </Button>
            </Box>
            <Box my={4}>
              <Grid container spacing={2}>
                {sources
                  .filter((s) => s.communication !== true)
                  .map((source, index) => (
                    <ConnectSource
                      source={source}
                      key={source.key}
                      preferred={{
                        allDataSources: allDataSources?.filter((ds) => ds.dataProvider === source.key),
                        dataSource: dataSources?.find((ds) => ds.dataProvider === source.key),
                        setPreferred,
                      }}
                    />
                  ))}
                {(feeds ?? [])
                  .filter((s) => s.communication !== true)
                  .map((source, index) => (
                    <ConnectSource
                      source={source}
                      key={source.key}
                      preferred={{
                        allDataSources: [source],
                        dataSource: source,
                        setPreferred: setFeeds,
                      }}
                    />
                  ))}
              </Grid>
            </Box>
            <Typography variant="h3">{t('management.connectchannels2')}</Typography>
            <Box my={4}>
              <Grid container spacing={2}>
                {sources
                  .filter((s) => s.communication === true)
                  .map((source, index) => (
                    <ConnectSource
                      source={source}
                      key={source.key}
                      preferred={{
                        allDataSources: allDataSources?.filter((ds) => ds.dataProvider === source.key),
                        dataSource: dataSources?.find((ds) => ds.dataProvider === source.key),
                        setPreferred,
                      }}
                    />
                  ))}
              </Grid>
            </Box>
          </Box>
        </CardContent>
      </Card>
      <ApplyUrl />
    </>
  );
};

export default Sources;
