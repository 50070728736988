import { LoadingButton } from "@mui/lab";
import { Box, Button, ButtonGroup, Card, CardContent, LinearProgress, Stack, styled, TextField, Tooltip, Typography } from "@mui/material"
import { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from 'react-i18next'
import SaveIcon from '@mui/icons-material/Save';
import useAfasData from "../../hooks/useAfasData";
import { useLocation, useNavigate } from "react-router-dom";
import { afasPoster } from "../../hooks/useAfasDataMutation";
import { TokenContext } from "../../context/TokenContext";

// Styled component for the variable buttons container
const VariableButtonsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
}));
const ApplyUrl = () => {
  const token = useContext(TokenContext);
  const { t } = useTranslation('common');
  const navigate = useNavigate();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const lang = urlParams.get('lang') ?? 'nl';
  const displayLang = urlParams.get('displayLang') ?? lang;
  const [url, setUrl] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const inputRef = useRef(null);
  useEffect(() => {
    urlParams.set('displayLang', lang);
  }, [lang])


  const { data: atsConfig, isLoading: loadingAtsConfig } = useAfasData(`/atsconfig`, { language: displayLang });


  async function postSubmission(body) {
    const arg = { token, body };
    let postData = await afasPoster(`/atsconfig`, { arg });
    setIsLoading(false);
    return postData;
  }

  const insertVariable = (variable) => {
    const input = inputRef.current;
    if (!input) return;
    const start = input.selectionStart;
    const newUrl = url.substring(0, start) + variable;
    setUrl(newUrl);

    // Set focus back to input and update cursor position
    setTimeout(() => {
      input.focus();
    }, 0);
  };

  const handleSave = async () => {
    setIsLoading(true);
    try {
      const data = {
        ApplyURL: url,
        language: displayLang,
      };
      await postSubmission(data);
      // Handle success
      console.log('URL saved successfully');
    } catch (error) {
      console.error('Error saving URL:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (atsConfig && atsConfig.ApplyURL) {
      setUrl(atsConfig.ApplyURL);
    }
  }, [atsConfig]);

  return (
    <>
      <Box mt={4}>
        <Card
          sx={(theme) => ({
            borderRadius: '10px',
            boxShadow: theme.shadows[1],
            backgroundColor: 'white',
          })}
        >
          <CardContent
            sx={{
              padding: '3.5rem 4.5rem',
            }}
          >
            <Box mb={1}>
              <Typography variant="h3">{t('management.apply_url')}</Typography>
            </Box>
            <Box mt={2} mb={4}>
              <Typography mb={1} variant="body1" color="initial">{t('management.mastertemplate.displaylang')}</Typography>
              <ButtonGroup aria-label="Select language">
                <Button
                  variant={displayLang === 'nl' ? 'contained' : 'outlined'}
                  onClick={() => {
                    urlParams.set('displayLang', 'nl');
                    navigate(
                      {
                        pathname: `/management/sources/`,
                        search: urlParams.toString(),
                      }
                    );
                  }}
                  sx={(theme) => ({
                    border: `1px solid ${theme.vars.palette.primary.main}`
                  })}>
                  <img
                    src="https://flagcdn.com/w40/nl.png"
                    alt="Dutch flag"
                    style={{ width: '24px', height: '16px' }}
                  />
                </Button>
                <Button
                  variant={displayLang === 'en' ? 'contained' : 'outlined'}
                  onClick={() => {
                    urlParams.set('displayLang', 'en');
                    navigate(
                      {
                        pathname: `/management/sources/`,
                        search: urlParams.toString(),
                      }
                    );
                  }}
                  sx={(theme) => ({
                    border: `1px solid ${theme.vars.palette.primary.main}`
                  })}>
                  <img
                    src="https://flagcdn.com/w40/gb.png"
                    alt="English flag"
                    style={{ width: '24px', height: '16px' }}
                  />
                </Button>
              </ButtonGroup>
            </Box>
            {loadingAtsConfig && (
              <LinearProgress />
            )}
            {!loadingAtsConfig && atsConfig && (
              <Stack direction="row" alignItems="center" spacing={2} useFlexGap>
                <TextField
                  ref={inputRef}
                  value={url}
                  fullWidth
                  id="outlined-required"
                  label={t('management.apply_url')}
                  onChange={(e) => setUrl(e.target.value)}
                />

                <VariableButtonsContainer>
                  <Tooltip title={t('management.insert_variable')}>
                    <Button
                      variant="text"
                      onClick={() => insertVariable('[Title]')}
                      size="medium"
                    >
                      [Title]
                    </Button>
                  </Tooltip>
                  <Tooltip title={t('management.insert_variable')}>
                    <Button
                      variant="text"
                      onClick={() => insertVariable('[VacancyID]')}
                      size="medium"
                    >
                      [VacancyID]
                    </Button>
                  </Tooltip>
                </VariableButtonsContainer>

                <LoadingButton
                  loading={isLoading}
                  variant="contained"
                  onClick={handleSave}
                  endIcon={<SaveIcon />}
                  loadingPosition="end"
                >
                  {t('management.save')}
                </LoadingButton>
              </Stack>
            )}
          </CardContent>
        </Card>
      </Box>
    </>
  );
}

export default ApplyUrl;