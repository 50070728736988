import { Box, Button, Divider, Grid, LinearProgress, Paper, Step, StepLabel, Stepper } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Step2 from '../components/Step2';
import Step3 from '../components/Step3';
import Step4 from '../components/Step4';
import { afasPoster } from '../hooks/useAfasDataMutation';
import { TokenContext } from '../context/TokenContext';
import { LoadingButton } from '@mui/lab';
import { useParams } from 'react-router-dom';
import { InSiteContext } from '../context/InSiteContext';
import { DatahubContext } from '../context/DatahubContext';
import useAfasData from '../hooks/useAfasData';

const New = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const formRef = useRef();
  const { t } = useTranslation('common');
  const urlParams = new URLSearchParams(window.location.search);
  const lang = urlParams.get('lang') ?? 'nl';
  // console.log({ lang });
  const token = useContext(TokenContext);
  const { insiteUrl } = useContext(InSiteContext);

  const [enriched, setEnriched] = useState(false);

  let params = useParams();
  let vacancyId = params?.VacancyID;
  const [vacancyData, setVacancyData] = useState({
    vacancyId: vacancyId ?? searchParams.get('VcSn'),
    vacancyTitle: '',
  });
  const [langSelector, setLangSelector] = useState(lang);
  useEffect(() => {
    if (lang) {
      setLangSelector(lang);
    }
  }, [lang]);

  const [activeStep, setActiveStep] = useState(1);

  const { data: vacancyDataRemote, isLoading: vacancyDataIsLoading } = useAfasData(
    `/vacancy/${searchParams.get('VcSn')}`,
    { language: langSelector },
  );

  const { data: atsConfig } = useAfasData(`/atsconfig`, { language: langSelector });

  const [submitting, setSubmitting] = useState(false);

  const { workspace } = useContext(DatahubContext);

  const [googleJobs, setGoogleJobs] = useState(false);
  const [indeed, setIndeed] = useState(false);
  const [facebook, setFacebook] = useState(false);
  const [linkedin, setLinkedin] = useState(false);

  const channels = [
    {
      name: 'Google Jobs',
      key: 'google_jobs',
      checked: googleJobs,
      setter: setGoogleJobs,
      image: '/images/google_jobs.png',
      available: true,
    },
    {
      name: 'Indeed',
      key: 'INDEED_ATS',
      checked: indeed,
      setter: setIndeed,
      image: '/images/indeed.png',
      available: true,
    },
    {
      name: 'Facebook',
      key: 'facebook',
      checked: facebook,
      setter: setFacebook,
      image: '/images/facebook_ads.png',
      available: false,
    },
    {
      name: 'Linkedin',
      key: 'linkedin',
      checked: linkedin,
      setter: setLinkedin,
      image: '/images/linkedin.png',
      available: false,
    },
  ];

  const [googleAds, setGoogleAds] = useState(false);
  const [googleAdsFormData, setGoogleAdsFormData] = useState({});
  const [emailFormData, setEmailFormData] = useState({});
  const [teamsFormData, setTeamsFormData] = useState({});

  const [bingAds, setBingAds] = useState(false);
  // const [linkedinAds, setLinkedinAds] = useState(false);
  // const [facebookAds, setFacebookAds] = useState(false);
  const [indeedAds, setIndeedAds] = useState(false);

  const adchannels = [
    {
      name: 'Google Ads',
      key: 'GOOGLE_ADS',
      checked: googleAds,
      setter: setGoogleAds,
      image: '/images/google_ads.png',
      available: true,
      formData: googleAdsFormData,
      setFormData: (e) => {
        setGoogleAdsFormData({
          ...googleAdsFormData,
          [`${e.target.name}`]: {
            ...googleAdsFormData[`${e.target.name}`],
            value: e.target.value,
          },
        });
      },
      open: googleAdsFormData['open'] ?? false,
      setOpen: (v) => setGoogleAdsFormData({ ...googleAdsFormData, open: v }),
    },
    {
      name: 'Bing Ads',
      key: 'bing_ads', // key should be the same as Datahub DataProviderName
      checked: bingAds,
      setter: setBingAds,
      image: '/images/bing_ads.png',
      available: false,
    },
    // {
    //   name: 'Linkedin Ads',
    //   key: 'linkedin_ads',
    //   checked: linkedinAds,
    //   setter: setLinkedinAds,
    //   image: '/images/linkedin_ads.png',
    //   available: false,
    // },
    // {
    //   name: 'Facebook Ads',
    //   key: 'facebook_ads',
    //   checked: facebookAds,
    //   setter: setFacebookAds,
    //   image: '/images/facebook_ads.png',
    //   available: false,
    // },
    {
      name: 'Indeed Ads',
      key: 'indeed_ads',
      checked: indeedAds,
      setter: setIndeedAds,
      image: '/images/indeed_ads.png',
      available: false,
    },
  ];

  const [teams, setTeams] = useState(false);
  const [outlook, setOutlook] = useState(false);
  const [insite, setInsite] = useState(true);
  const spreadChannels = [
    {
      name: 'Microsoft Teams',
      key: 'MICROSOFT_TEAMS_CHAT',
      checked: teams,
      setter: setTeams,
      image: '/images/teams.png',
      formData: teamsFormData,
      setFormData: setTeamsFormData,
      open: teamsFormData['open'] ?? false,
      setOpen: (v) => setTeamsFormData({ ...teamsFormData, open: v }),
    },
    {
      name: 'Microsoft Outlook',
      key: 'MAIL',
      checked: outlook,
      setter: setOutlook,
      image: '/images/outlook.png',
      formData: emailFormData,
      setFormData: setEmailFormData,
      open: emailFormData['open'] ?? false,
      setOpen: (v) => setEmailFormData({ ...emailFormData, open: v }),
    },
    {
      name: 'AFAS Insite',
      key: 'afas_insite',
      checked: insite,
      setter: setInsite,
      image: '/images/insite.png',
    },
  ];

  const [customChannels, setCustomChannels] = useState([]);

  useEffect(() => {
    if (vacancyDataRemote && vacancyDataRemote?.VacancyId) {
      const channels = vacancyDataRemote?.Kanalen?.split(' ') ?? [];
      setEnriched(true);
      vacancyDataRemote.vacancyId = vacancyDataRemote?.VacancyId;
      setVacancyData(vacancyDataRemote);
      setGoogleJobs(channels.includes('google_jobs'));
      setIndeed(channels.includes('INDEED_ATS'));
      setFacebook(channels.includes('facebook'));
      setLinkedin(channels.includes('linkedin'));
      setBingAds(channels.includes('bing_ads'));
      setGoogleAds(channels.includes('GOOGLE_ADS'));
      // setLinkedinAds(vacancyDataRemote?.Linkedin_ads);
      // setFacebookAds(vacancyDataRemote?.Facebook_ads);
      setIndeedAds(channels.includes('indeed_ads'));
      setTeams(channels.includes('MICROSOFT_TEAMS_CHAT'));
      setOutlook(channels.includes('MAIL'));
      setInsite(channels.includes('afas_insite'));
      const settings = JSON.parse(vacancyDataRemote?.Channel_settings ?? '{}');
      setTeamsFormData(settings?.MICROSOFT_TEAMS_CHAT ?? {});
      setEmailFormData(settings?.MAIL ?? {});
      setGoogleAdsFormData(settings?.GOOGLE_ADS ?? {});
      setCustomChannels(channels);
    }
  }, [vacancyDataRemote, vacancyDataIsLoading]);

  const handleNext = () => {
    if (activeStep <= 3) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  async function postSubmission(body) {
    const arg = { token, body, workspace };
    let postData = await afasPoster(`/details`, { arg });
    setSubmitting(false);
    return postData;
  }

  async function handleSubmission(e) {
    e.preventDefault();
    const formData = new FormData(formRef.current);
    const data = {
      fields: Object.fromEntries(formData.entries()),
      adchannels: adchannels.filter((channel) => channel.checked),
      channels: channels.filter((channel) => channel.checked),
      spreadchannels: spreadChannels.filter((channel) => channel.checked),
      customChannels: [...new Set(customChannels)],
      dataHubWorkspaceId: workspace ? workspace.id : '',
      language: langSelector,
    };
    setSubmitting(true);
    // console.log(data);
    // MD conversion w/ TurndownService (need options & rules)
    try {
      await postSubmission(data);
      if (insiteUrl && !enriched) {
        window.top.location.href = `${insiteUrl}/gladior-ats/ats`;
      } else {
        window.top.location.href = `${insiteUrl}${searchParams.get('referrer')}/?VcSn=${searchParams.get(
          'VcSn',
        )}`;
      }
    } catch (error) {
      throw error;
    }
  }

  return (
    <>
      {vacancyDataIsLoading && <LinearProgress />}
      <Box
        sx={{
          height: '100%',
          width: '100%',
        }}
      >
        {vacancyData?.vacancyId && (
          <form ref={formRef}>
            {vacancyData?.Dossieritem && (
              <input type="hidden" name="dossieritem" value={vacancyData?.Dossieritem} />
            )}
            <input type="hidden" name="vacancy_id" value={vacancyData?.vacancyId} />
            <Grid container spacing={4} mb={2} pl={2} pr={4} mt={0}>
              <Grid item xs={12}>
                <Typography variant="h1" color="initial">
                  {vacancyData?.vacancyTitle && <>{vacancyData?.vacancyTitle}</>}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Box>
                  <Paper
                    elevation={3}
                    sx={{ p: 4, borderRadius: '1rem', boxShadow: '10px 10px 30px 0px rgba(0, 0, 0, 0.15)' }}
                  >
                    <Stepper
                      activeStep={activeStep}
                      orientation="vertical"
                      sx={{
                        '& .MuiStepConnector-root': {
                          marginLeft: '18px',
                        },
                        '& .MuiStepConnector-line': {
                          minHeight: '48px',
                        },
                      }}
                    >
                      <Step key={1}>
                        <StepLabel>{t('steps.titles.step_1')}</StepLabel>
                      </Step>
                      <Step key={2}>
                        <StepLabel>{t('steps.titles.step_2')}</StepLabel>
                      </Step>
                      <Step key={3}>
                        <StepLabel>{t('steps.titles.step_3')}</StepLabel>
                      </Step>
                      <Step key={4}>
                        <StepLabel>{t('steps.titles.step_4')}</StepLabel>
                      </Step>
                    </Stepper>
                  </Paper>
                </Box>
              </Grid>
              <Grid item xs={9}>
                <Box mb={4}>
                  <Step2
                    config={atsConfig}
                    vacancy={vacancyData}
                    langSelector={langSelector}
                    setLangSelector={setLangSelector}
                    sx={{ display: activeStep === 1 ? 'block' : 'none' }}
                  />
                  <Step3
                    channels={channels}
                    adchannels={adchannels}
                    vacancy={vacancyData}
                    sx={{ display: activeStep === 2 ? 'block' : 'none' }}
                    setCustomChannels={setCustomChannels}
                    selectedCustomChannels={customChannels}
                    langSelector={langSelector}
                    setLangSelector={setLangSelector}
                  />
                  <Step4
                    channels={spreadChannels}
                    vacancy={vacancyData}
                    sx={{ display: activeStep === 3 ? 'block' : 'none' }}
                    langSelector={langSelector}
                    setLangSelector={setLangSelector}
                  />
                </Box>
                <Divider />
                <Box mt={4}>
                  <Button onClick={handleBack} disabled={activeStep === 1} sx={{ mt: 1, mr: 1 }}>
                    {t('back')}
                  </Button>
                  {activeStep < 3 && (
                    <Button variant="contained" onClick={handleNext} sx={{ mt: 1, mr: 1 }}>
                      {t('next')}
                    </Button>
                  )}
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    loading={submitting}
                    onClick={handleSubmission}
                    style={{ marginTop: '1em', display: activeStep === 3 ? 'inline-block' : 'none' }}
                  >
                    {enriched ? t('update') : t('complete')}
                  </LoadingButton>
                </Box>
              </Grid>
            </Grid>
          </form>
        )}
      </Box>
    </>
  );
};

export default New;
