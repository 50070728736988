import { Box, Button, IconButton, Popover, Typography } from "@mui/material";
import { useState } from "react";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useTranslation } from "react-i18next";

const DeleteSubFunctionButton = ({ subFunc, removeSubFunction }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { t } = useTranslation('common');

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (e) => {
    setAnchorEl(null);
    e.stopPropagation();
  };

  const handleConfirm = () => {
    removeSubFunction(subFunc.Index);
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'delete-popover' : undefined;

  return (
    <>
      <IconButton
        size="small"
        sx={{
          marginLeft: 'auto',
        }}
        onClick={(e) => {
          handleOpen(e);
          e.stopPropagation();
        }}
      >
        <DeleteForeverIcon />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box p={2}>
          <Typography variant="body1" gutterBottom>
            {t('management.mastertemplate.confirmdeletion')}
          </Typography>
          <Box display="flex" justifyContent="flex-end" gap={1}>
            <Button variant="outlined" size="small" onClick={handleClose}>
              {t('close')}
            </Button>
            <Button variant="contained" color="error" size="small" onClick={handleConfirm}>
              {t('delete')}
            </Button>
          </Box>
        </Box>
      </Popover>
    </>
  );
};
export default DeleteSubFunctionButton;