import {
  Box,
  DialogTitle,
  IconButton,
  Button,
  Alert,
  DialogContent,
  DialogActions,
  Stack,
  Avatar,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
  Link,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import { useContext, useState } from 'react';
import { TokenContext } from '../../context/TokenContext';
import { InSiteContext } from '../../context/InSiteContext';
import { DatahubContext } from '../../context/DatahubContext';

const Teams = ({ channel, vacancy, onClose, dataSources }) => {
  const { t } = useTranslation('common');
  const token = useContext(TokenContext);
  const { environmentId } = useContext(InSiteContext);
  const { workspace } = useContext(DatahubContext);

  const { setFormData, name, formData, setter } = channel;

  const [valid, setValid] = useState(null);

  const validate = () => {
    if (valid) return true;
    if (formData['to'] && formData['frequency'] && formData['lang']) {
      setValid(true);
      setTimeout(function () {
        onClose();
      }, 1000);
    } else {
      setValid(false);
    }
  };
  return (
    <>
      <DialogTitle>
        <Box px={4} py={1}>
          <Stack
            direction="row"
            gap={2}
            sx={{
              alignItems: 'center',
            }}
          >
            <span
              style={{
                fontSize: '1.5rem',
              }}
            >
              {t('confighere')}
              <br></br>
              {`${name}`}
            </span>
            <Avatar
              variant="square"
              src={channel.image}
              sx={{
                marginLeft: '1rem',
                width: '5rem',
                height: '3rem',
                '& .MuiAvatar-img': { objectFit: 'contain' },
              }}
            />
            <IconButton
              aria-label={t('close')}
              onClick={() => {
                onClose();
              }}
              sx={{ marginLeft: 'auto' }}
            >
              <CloseIcon />
            </IconButton>
          </Stack>
        </Box>
      </DialogTitle>
      <DialogContent dividers={true}>
        <Box px={4} py={2}>
          <FormControl fullWidth>
            <InputLabel id="label-2">{t('channelconfig.language')}</InputLabel>
            <Select
              label={t('channelconfig.language')}
              labelId="label-2"
              value={formData?.['lang'] ?? ''}
              onChange={(event) => {
                setFormData({
                  ...formData,
                  lang: event.target.value,
                });
              }}
            >
              {[
                { id: 'NL', value: t('channelconfig.dutch') },
                { id: 'EN', value: t('channelconfig.english') },
                { id: 'NL_EN', value: `${t('channelconfig.dutch')} & ${t('channelconfig.english')}` },
              ].map((v) => (
                <MenuItem value={v.id} key={v.id}>
                  {v.value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box px={4} py={2}>
          <FormControl fullWidth>
            <InputLabel id="label-1">{t('channelconfig.frequency')}</InputLabel>
            <Select
              label={t('channelconfig.frequency')}
              labelId="label-1"
              value={formData?.['frequency'] ?? ''}
              onChange={(event) => {
                setFormData({
                  ...formData,
                  frequency: event.target.value,
                });
              }}
            >
              {[
                { id: 'ALWAYS', value: t('channelconfig.immediatly') },
                { id: 'HOUR', value: t('channelconfig.hourly') },
                { id: 'DAY', value: t('channelconfig.daily') },
                { id: 'WEEK', value: t('channelconfig.weekly') },
                { id: 'MONTH', value: t('channelconfig.monthly') },
              ].map((v) => (
                <MenuItem value={v.id} key={v.id}>
                  {v.value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box px={4} py={2}>
          <FormControl fullWidth>
            <InputLabel id="label">{t('channelconfig.to')}</InputLabel>
            <Select
              label={t('channelconfig.to')}
              labelId="label"
              value={formData?.['to'] ?? ''}
              onChange={(event) => {
                setFormData({
                  ...formData,
                  to: event.target.value,
                });
              }}
            >
              {dataSources.map((ds) => (
                <MenuItem value={ds.id} key={ds.id}>
                  {ds.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Typography
            sx={{
              fontSize: '0.8rem',
              opacity: '0.8',
            }}
          >
            {t('channelconfig.teams.addrecipient')}
            <Link
              href={`${process.env.REACT_APP_DATA_HUB_UI_URL}/workspace/${workspace?.id}/data-sources/create/data-source?dataProvider=MICROSOFT_TEAMS_CHAT&afasToken=${token}&environment=${environmentId}`}
              onClick={async (event) => {
                event.preventDefault();
                event.stopPropagation();
                window
                  .open(
                    `${process.env.REACT_APP_DATA_HUB_UI_URL}/workspace/${workspace?.id}/data-sources/create/data-source?dataProvider=MICROSOFT_TEAMS_CHAT&afasToken=${token}&environment=${environmentId}`,
                    '_blank',
                  )
                  .focus();
              }}
            >
              {t('channelconfig.teams.datahub')}
            </Link>
            .
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box px={4} py={1} sx={{ width: '100%' }}>
          <Stack gap={2}>
            {valid === false && <Alert severity="error">{t('form.missingrequired')}</Alert>}
            {valid === true && <Alert severity="success">{t('form.success')}</Alert>}
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                validate();
              }}
            >
              {t('form.complete')}
            </Button>
          </Stack>
        </Box>
      </DialogActions>
    </>
  );
};

export default Teams;
