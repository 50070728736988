import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useAfasData from '../../hooks/useAfasData';
import { afasPoster } from '../../hooks/useAfasDataMutation';
import { TokenContext } from '../../context/TokenContext';
import {
  Alert,
  Box,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  Grid,
  LinearProgress,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
import { TextFieldLabel } from '../../containers/management/Sources';
import { useNavigate } from 'react-router-dom';
import { copyTextToClipboard } from '../../utils';

const ConfigForm = () => {
  const token = useContext(TokenContext);

  const configFormRef = useRef();
  const [submitting, setSubmitting] = useState(false);
  const navigate = useNavigate();

  const { t } = useTranslation('common');

  const urlParams = new URLSearchParams(window.location.search);
  const lang = urlParams.get('lang') ?? 'nl';
  const displayLang = urlParams.get('displayLang') ?? lang;

  const { data: atsConfig, isLoading: loadingAtsConfig } = useAfasData(`/atsconfig`, { language: displayLang });

  async function postSubmission(body) {
    const arg = { token, body };
    let postData = await afasPoster(`/atsconfig`, { arg });
    setSubmitting(false);
    return postData;
  }
  async function handleConfigSubmission(e) {
    e.preventDefault();
    const formData = new FormData(configFormRef.current);
    const data = {
      ...Object.fromEntries(formData.entries()),
      language: displayLang,
    };
    setSubmitting(true);
    console.log(data);
    try {
      await postSubmission(data);
    } catch (error) {
      throw error;
    }
  }

  const variables = [
    '[Begindatum]',
    '[Einddatum]',
    '[Titel]',
    '[Opleidingsniveau]',
    '[Formatieplaats]',
    '[Reden]',
    '[Functie]',
    '[Werkervaring]',
    '[FunctieOmschrijvingInput]',
    '[FunctieEisenInput]',
    '[Competenties]',
    '[Land]',
    '[Plaats]',
    '[Postcode]',
    '[Huisnummer]',
    '[Provincie]',
    '[Uren]',
  ];

  const [systemprompt, setSystemPrompt] = useState('');
  const [baseprompt, setBasePrompt] = useState('');
  const [prompt1, setPrompt1] = useState('');
  const [prompt2, setPrompt2] = useState('');
  const [prompt3, setPrompt3] = useState('');
  const [prompt4, setPrompt4] = useState('');
  const [heading1, setHeading1] = useState('');
  const [heading2, setHeading2] = useState('');
  const [heading3, setHeading3] = useState('');
  const [heading4, setHeading4] = useState('');
  const [copying, setCopying] = useState(false);
  useEffect(() => {
    if (atsConfig) {
      setSystemPrompt(atsConfig.SystemPrompt);
      setBasePrompt(atsConfig.BasePrompt);
      setPrompt1(atsConfig.Memo1Prompt);
      setPrompt2(atsConfig.Memo2Prompt);
      setPrompt3(atsConfig.Memo3Prompt);
      setPrompt4(atsConfig.Memo4Prompt);
      setHeading1(atsConfig.Heading1);
      setHeading2(atsConfig.Heading2);
      setHeading3(atsConfig.Heading3);
      setHeading4(atsConfig.Heading4);
    }
  }, [atsConfig]);

  return (
    <>
      <Box mt={4}>
        <Card
          style={{
            borderRadius: '10px',
            boxShadow: '10px 10px 30px 0px rgba(0, 0, 0, 0.1)',
            backgroundColor: 'white',
          }}
        >
          <CardContent
            sx={{
              padding: '3.5rem 4.5rem',
            }}
          >
            <Typography variant="h3">
              {t('management.enrichmentconfig')}
            </Typography>
            <Box mt={4}>
              <Typography mb={1} variant="body1" color="initial">{t('management.mastertemplate.displaylang')}</Typography>
              <ButtonGroup aria-label="Select language">
                <Button
                  variant={displayLang === 'nl' ? 'contained' : 'outlined'}
                  onClick={() => {
                    urlParams.set('displayLang', 'nl');
                    navigate(
                      {
                        pathname: `/management/gptconfig/`,
                        search: urlParams.toString(),
                      }
                    );
                  }}
                  sx={(theme) => ({
                    border: `1px solid ${theme.vars.palette.primary.main}`
                  })}>
                  <img
                    src="https://flagcdn.com/w40/nl.png"
                    alt="Dutch flag"
                    style={{ width: '24px', height: '16px' }}
                  />
                </Button>
                <Button
                  variant={displayLang === 'en' ? 'contained' : 'outlined'}
                  onClick={() => {
                    urlParams.set('displayLang', 'en');
                    navigate(
                      {
                        pathname: `/management/gptconfig/`,
                        search: urlParams.toString(),
                      }
                    );
                  }}
                  sx={(theme) => ({
                    border: `1px solid ${theme.vars.palette.primary.main}`
                  })}>
                  <img
                    src="https://flagcdn.com/w40/gb.png"
                    alt="English flag"
                    style={{ width: '24px', height: '16px' }}
                  />
                </Button>
              </ButtonGroup>
            </Box>
            {loadingAtsConfig && <LinearProgress />}
            {!loadingAtsConfig && atsConfig && (
              <Box my={4}>
                <Typography my={2} variant="body1" color="initial">
                  {t('management.variablestouse')}
                </Typography>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mb: 2, mt: 2 }}>
                  <ButtonGroup
                    sx={{
                      flexWrap: 'wrap',
                    }}
                    size="small"
                    variant="text"
                  >
                    {variables.map((variable) => (
                      <Tooltip title={t('copy')}>
                        <Button
                          key={variable}
                          sx={(theme) => ({
                            color: theme.vars.palette.primary.contrastText,
                            textTransform: 'none',
                          })}
                          variant="text"
                          onClick={() => {
                            copyTextToClipboard(variable);
                            setCopying(variable)
                            setTimeout(() => {
                              setCopying(false);
                            }, 3000);
                          }}
                        >
                          {variable}
                        </Button>
                      </Tooltip>
                    ))}
                  </ButtonGroup>
                  <Box sx={{
                    minHeight: '48px',
                  }} mt={1}>
                    {copying && (
                      <Alert severity="success">
                        {copying} {t('copied')}
                      </Alert>
                    )}
                  </Box>
                </Box>
                <form ref={configFormRef}>
                  <input type="hidden" name="MarketingChannels" value={atsConfig.MarketingChannels} />
                  <Box mt={6}>
                    <Grid container spacing={2} mb={4}>
                      <Grid item xs={6}>
                        <Box mb={2}>
                          <TextFieldLabel variant="caption">
                            {t('management.labels.systemprompt')}
                          </TextFieldLabel>
                          <TextField
                            multiline
                            minRows={7}
                            fullWidth
                            variant="filled"
                            value={systemprompt}
                            name="SystemPrompt"
                            onChange={(e) => setSystemPrompt(e.target.value)}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <Box mb={2}>
                          <TextFieldLabel variant="caption">
                            {t('management.labels.baseprompt')}
                          </TextFieldLabel>
                          <TextField
                            multiline
                            minRows={7}
                            fullWidth
                            variant="filled"
                            value={baseprompt}
                            name="BasePrompt"
                            onChange={(e) => setBasePrompt(e.target.value)}
                          />
                        </Box>
                      </Grid>
                    </Grid>

                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Box mb={2}>
                          <TextFieldLabel variant="caption">{t('management.labels.heading1')}</TextFieldLabel>
                          <TextField
                            margin="normal"
                            value={heading1}
                            onChange={(e) => setHeading1(e.target.value)}
                            name={'Heading1'}
                            sx={{
                              width: '100%',
                            }}
                          />
                        </Box>

                        <Box mb={2}>
                          <TextFieldLabel variant="caption">{t('management.labels.prompt1')}</TextFieldLabel>
                          <TextField
                            multiline
                            minRows={6}
                            fullWidth
                            variant="filled"
                            value={prompt1}
                            name="Memo1Prompt"
                            onChange={(e) => setPrompt1(e.target.value)}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <Box mb={2}>
                          <TextFieldLabel variant="caption">{t('management.labels.heading3')}</TextFieldLabel>
                          <TextField
                            margin="normal"
                            value={heading3}
                            onChange={(e) => setHeading3(e.target.value)}
                            name={'Heading3'}
                            sx={{
                              width: '100%',
                            }}
                          />
                        </Box>

                        <Box mb={2}>
                          <TextFieldLabel variant="caption">{t('management.labels.prompt3')}</TextFieldLabel>
                          <TextField
                            multiline
                            minRows={6}
                            fullWidth
                            variant="filled"
                            value={prompt3}
                            name="Memo3Prompt"
                            onChange={(e) => setPrompt3(e.target.value)}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <Box mb={2}>
                          <TextFieldLabel variant="caption">{t('management.labels.heading2')}</TextFieldLabel>
                          <TextField
                            margin="normal"
                            value={heading2}
                            onChange={(e) => setHeading2(e.target.value)}
                            name={'Heading2'}
                            sx={{
                              width: '100%',
                            }}
                          />
                        </Box>

                        <Box mb={2}>
                          <TextFieldLabel variant="caption">{t('management.labels.prompt2')}</TextFieldLabel>
                          <TextField
                            multiline
                            minRows={6}
                            fullWidth
                            variant="filled"
                            value={prompt2}
                            name="Memo2Prompt"
                            onChange={(e) => setPrompt2(e.target.value)}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <Box mb={2}>
                          <TextFieldLabel variant="caption">{t('management.labels.heading4')}</TextFieldLabel>
                          <TextField
                            margin="normal"
                            value={heading4}
                            onChange={(e) => setHeading4(e.target.value)}
                            name={'Heading4'}
                            sx={{
                              width: '100%',
                            }}
                          />
                        </Box>

                        <Box mb={2}>
                          <TextFieldLabel variant="caption">{t('management.labels.prompt4')}</TextFieldLabel>
                          <TextField
                            multiline
                            minRows={6}
                            fullWidth
                            variant="filled"
                            value={prompt4}
                            name="Memo4Prompt"
                            onChange={(e) => setPrompt4(e.target.value)}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                    <Box mt={2}>
                      <Stack direction={'row'} justifyContent={'flex-end'} alignItems={'center'}>
                        <LoadingButton
                          type="submit"
                          variant="contained"
                          loading={submitting}
                          endIcon={<SaveIcon />}
                          onClick={handleConfigSubmission}
                        >
                          {t('update')}
                        </LoadingButton>
                      </Stack>
                    </Box>
                  </Box>
                </form>
              </Box>
            )}
          </CardContent>
        </Card>
      </Box>
    </>
  );
};

export default ConfigForm;
