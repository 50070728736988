import { Autocomplete, Checkbox, Chip, TextField } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { useTranslation } from 'react-i18next';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const GoogleAdsKeywords = ({ value, vacancy, fieldId, setFormData }) => {
  const { t } = useTranslation('common');
  const options = [
    {
      title: `${t('channelconfig.gads.vacancy')} ${vacancy?.VacancyTitle}`,
      id: 1,
    },
    {
      title: `${vacancy?.VacancyTitle} ${t('channelconfig.gads.vacancies')}`,
      id: 2,
    },
    {
      title: `${vacancy?.VacancyTitle} ${t('channelconfig.gads.search')}`,
      id: 3,
    },
    {
      title: `${t('channelconfig.gads.vacancy')} + ${vacancy?.VacancyTitle} + ${vacancy?.Plaats}`,
      id: 4,
    },
    {
      title: `${t('channelconfig.gads.career')} ${vacancy?.VacancyTitle}`,
      id: 5,
    },
    {
      title: `${t('channelconfig.gads.job')} ${vacancy?.VacancyTitle}`,
      id: 6,
    },

    {
      title: `${t('channelconfig.gads.function')} ${vacancy?.VacancyTitle}`,
      id: 8,
    },
    {
      title: `${t('channelconfig.gads.apply')} ${vacancy?.VacancyTitle}`,
      id: 9,
    },
  ];

  return (
    <>
      <Autocomplete
        multiple
        id={fieldId}
        freeSolo
        options={options.map((option) => option.title)}
        value={
          value?.map((option) =>
            option?.replace('TITLE', vacancy?.VacancyTitle).replace('CITY', vacancy?.Plaats),
          ) ?? []
        }
        onChange={(e, newValue) => {
          setFormData({
            target: {
              name: fieldId,
              value: newValue,
            },
          });
        }}
        renderOption={(props, option, { selected }) => (
          <li key={option.id} {...props}>
            <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
            {option.title ?? option}
          </li>
        )}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => <Chip variant="outlined" label={option} {...getTagProps({ index })} />)
        }
        renderInput={(params) => (
          <TextField {...params} label={t('channelconfig.gads.keywords')} placeholder="Keyword" />
        )}
      />
    </>
  );
};

export default GoogleAdsKeywords;
