import {
  Box,
  DialogTitle,
  IconButton,
  TextField,
  Button,
  Alert,
  DialogContent,
  DialogActions,
  Stack,
  Avatar,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import GoogleAdsKeywords from './fields/GoogleAdsKeywords';
import GoogleAdsBudget from './fields/GoogleAdsBudget';
import { useState } from 'react';

const GoogleAds = ({ channel, vacancy, onClose }) => {
  const { t } = useTranslation('common');
  const { setFormData, name, formData, setter } = channel;
  const [valid, setValid] = useState(null);
  const validate = () => {
    if (valid) return true;
    const keys = [
      'GOOGLE_ADS_HEADLINE1',
      'GOOGLE_ADS_HEADLINE2',
      'GOOGLE_ADS_HEADLINE3',
      'GOOGLE_ADS_DESCRIPTION1',
      'GOOGLE_ADS_DESCRIPTION2',
      'GOOGLE_ADS_KEYWORDS',
      'GOOGLE_ADS_BUDGET',
    ];
    if (
      keys.every((key) => {
        if (Array.isArray(formData?.[key]?.value)) {
          return formData[key].value.length > 0;
        }
        return formData?.[key]?.value;
      })
    ) {
      setValid(true);
      setTimeout(function () {
        onClose();
      }, 1000);
    } else {
      setValid(false);
    }
  };
  // dialog for google ads
  return (
    <>
      <DialogTitle>
        <Box px={4} py={1}>
          <Stack
            direction="row"
            gap={2}
            sx={{
              alignItems: 'center',
            }}
          >
            <span
              style={{
                fontSize: '1.5rem',
              }}
            >
              {t('confighere')}
              <br></br>
              {`${name}`}
            </span>
            <Avatar
              variant="square"
              src={channel.image}
              sx={{
                marginLeft: '1rem',
                width: '5rem',
                height: '3rem',
                '& .MuiAvatar-img': { objectFit: 'contain' },
              }}
            />
            <IconButton
              aria-label={t('close')}
              onClick={() => {
                onClose();
                setter(false);
              }}
              sx={{ marginLeft: 'auto' }}
            >
              <CloseIcon />
            </IconButton>
          </Stack>
        </Box>
      </DialogTitle>
      <DialogContent dividers={true}>
        <Box px={4} py={2}>
          <Box mb={4} key={'GOOGLE_ADS_HEADLINE1'}>
            <TextField
              required
              fullWidth
              inputProps={{
                maxLength: 30,
              }}
              label={`${t('channelconfig.gads.headline')} 1`}
              type="text"
              error={!formData['GOOGLE_ADS_HEADLINE1']?.value}
              id={'GOOGLE_ADS_HEADLINE1'}
              name={'GOOGLE_ADS_HEADLINE1'}
              value={`${
                formData['GOOGLE_ADS_HEADLINE1']?.value
                  ?.replace('TITLE', vacancy?.vacancyTitle)
                  .replace('CITY', vacancy?.Plaats) ?? ''
              }`}
              placeholder={`${t('channelconfig.gads.headline1')
                ?.replace('TITLE', vacancy?.vacancyTitle)
                .replace('CITY', vacancy?.Plaats)}`}
              multiline
              onChange={setFormData}
              helperText={`Max 30 ${t('characters')}`}
            />
          </Box>
          <Box mb={4} key={'GOOGLE_ADS_HEADLINE2'}>
            <TextField
              required
              fullWidth
              inputProps={{
                maxLength: 30,
              }}
              label={`${t('channelconfig.gads.headline')} 2`}
              type="text"
              error={!formData['GOOGLE_ADS_HEADLINE2']?.value}
              id={'GOOGLE_ADS_HEADLINE2'}
              name={'GOOGLE_ADS_HEADLINE2'}
              value={`${
                formData['GOOGLE_ADS_HEADLINE2']?.value
                  ?.replace('TITLE', vacancy?.vacancyTitle)
                  .replace('CITY', vacancy?.Plaats) ?? ''
              }`}
              placeholder={`${t('channelconfig.gads.headline2')
                ?.replace('TITLE', vacancy?.vacancyTitle)
                .replace('CITY', vacancy?.Plaats)}`}
              multiline
              onChange={setFormData}
              helperText={`Max 30 ${t('characters')}`}
            />
          </Box>
          <Box mb={4} key={'GOOGLE_ADS_HEADLINE3'}>
            <TextField
              required
              fullWidth
              inputProps={{
                maxLength: 30,
              }}
              label={`${t('channelconfig.gads.headline')} 3`}
              type="text"
              error={!formData['GOOGLE_ADS_HEADLINE3']?.value}
              id={'GOOGLE_ADS_HEADLINE3'}
              name={'GOOGLE_ADS_HEADLINE3'}
              value={`${
                formData['GOOGLE_ADS_HEADLINE3']?.value
                  ?.replace('TITLE', vacancy?.vacancyTitle)
                  .replace('CITY', vacancy?.Plaats) ?? ''
              }`}
              placeholder={`${t('channelconfig.gads.headline3')
                ?.replace('TITLE', vacancy?.vacancyTitle)
                .replace('CITY', vacancy?.Plaats)}`}
              multiline
              onChange={setFormData}
              helperText={`Max 30 ${t('characters')}`}
            />
          </Box>
          <Box mb={4} key={'GOOGLE_ADS_DESCRIPTION1'}>
            <TextField
              required
              rows={2}
              inputProps={{
                maxLength: 90,
              }}
              sx={{
                width: '100%',
              }}
              label={`${t('channelconfig.gads.description')} 1`}
              type="text"
              error={!formData['GOOGLE_ADS_DESCRIPTION1']?.value}
              id={'GOOGLE_ADS_DESCRIPTION1'}
              name={'GOOGLE_ADS_DESCRIPTION1'}
              value={`${
                formData['GOOGLE_ADS_DESCRIPTION1']?.value
                  ?.replace('TITLE', vacancy?.vacancyTitle)
                  .replace('CITY', vacancy?.Plaats) ?? ''
              }`}
              placeholder={`${t('channelconfig.gads.description1')
                ?.replace('TITLE', vacancy?.vacancyTitle)
                .replace('CITY', vacancy?.Plaats)}`}
              multiline
              onChange={setFormData}
              helperText={`Max 90 ${t('characters')}`}
            />
          </Box>
          <Box mb={4} key={'GOOGLE_ADS_DESCRIPTION2'}>
            <TextField
              required
              rows={2}
              inputProps={{
                maxLength: 90,
              }}
              sx={{
                width: '100%',
              }}
              label={`${t('channelconfig.gads.description')} 2`}
              type="text"
              error={!formData['GOOGLE_ADS_DESCRIPTION2']?.value}
              id={'GOOGLE_ADS_DESCRIPTION2'}
              name={'GOOGLE_ADS_DESCRIPTION2'}
              value={`${
                formData['GOOGLE_ADS_DESCRIPTION2']?.value
                  ?.replace('TITLE', vacancy?.vacancyTitle)
                  .replace('CITY', vacancy?.Plaats) ?? ''
              }`}
              placeholder={`${t('channelconfig.gads.description2')
                ?.replace('TITLE', vacancy?.vacancyTitle)
                .replace('CITY', vacancy?.Plaats)}`}
              multiline
              onChange={setFormData}
              helperText={`Max 90 ${t('characters')}`}
            />
          </Box>
          <Box mb={4} key={'GOOGLE_ADS_KEYWORDS'}>
            <GoogleAdsKeywords
              setFormData={setFormData}
              value={formData['GOOGLE_ADS_KEYWORDS']?.value}
              fieldId={'GOOGLE_ADS_KEYWORDS'}
              vacancy={vacancy}
              key={'GOOGLE_ADS_KEYWORDS'}
            />
          </Box>
          <Box mb={4} key={'GOOGLE_ADS_BUDGET'}>
            <GoogleAdsBudget
              setFormData={setFormData}
              value={formData['GOOGLE_ADS_BUDGET']?.value}
              fieldId={'GOOGLE_ADS_BUDGET'}
              vacancy={vacancy}
              key={'GOOGLE_ADS_BUDGET'}
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box px={4} py={1} sx={{ width: '100%' }}>
          <Stack gap={2}>
            {valid === false && <Alert severity="error">{t('form.missingrequired')}</Alert>}
            {valid === true && <Alert severity="success">{t('form.success')}</Alert>}
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                validate();
              }}
            >
              {t('form.complete')}
            </Button>
          </Stack>
        </Box>
      </DialogActions>
    </>
  );
};

export default GoogleAds;
