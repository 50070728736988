import { Box, Button, ButtonGroup, Card, CardContent, CardHeader, IconButton, Popover, Stack, Tooltip, Typography } from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ContentPasteGoIcon from '@mui/icons-material/ContentPasteGo';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { copyTextToClipboard } from "../../utils";

const PreviewTemplate = ({ masterTemplate, fields, lang }) => {
  const { t } = useTranslation('common');
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElConfirm, setAnchorElConfirm] = useState(null);
  const [copying, setCopying] = useState(false);
  const [applying, setApplying] = useState(false);

  const myFields = fields.filter((field) => lang === 'en' ? field.key.includes('_en') : !field.key.includes('_en'));

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenConfirm = (event) => {
    setAnchorElConfirm(event.currentTarget);
  };

  const handleCloseConfirm = (e) => {
    setAnchorElConfirm(null);
  };

  const handleConfirm = () => {
    myFields.forEach((field, index) => {
      field.setter((masterTemplate[`Memo${index + 1}`]));
    })
    setAnchorElConfirm(null);
  };


  const open = Boolean(anchorEl);
  const openConfirm = Boolean(anchorElConfirm);
  const id = open ? 'simple-popover' : undefined;
  return (
    <>
      <ButtonGroup>
        <IconButton onClick={handleClick}>
          <Tooltip title={t('management.mastertemplate.preview')}>
            <VisibilityIcon />
          </Tooltip>
        </IconButton>
        <IconButton onClick={handleOpenConfirm}>
          <Tooltip title={t('apply')}>
            <ContentPasteGoIcon />
          </Tooltip>
        </IconButton>
      </ButtonGroup>
      <Popover
        id={id}
        open={openConfirm}
        anchorEl={anchorElConfirm}
        onClose={handleCloseConfirm}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box p={2} sx={{
          maxWidth: '350px'
        }}>
          <Typography variant="body1" gutterBottom>
            {t('management.mastertemplate.overwritemaster')}
          </Typography>
          <Box display="flex" justifyContent="flex-end" gap={1}>
            <Button variant="outlined" size="small" onClick={handleCloseConfirm}>
              {t('close')}
            </Button>
            <Button variant="contained" color="warning" size="small" onClick={handleConfirm}>
              {t('overwrite')}
            </Button>
          </Box>
        </Box>
      </Popover>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box p={2}
          sx={{
            minWidth: '50vw',
          }}>
          <Box mb={3}>
            <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
              <Typography variant="h4">{t('management.mastertemplate.preview')}</Typography>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Stack>
          </Box>
          {myFields.map((field, index) => (
            <>
              <Box my={2} key={field.key}>
                <Card
                  sx={(theme) => ({
                    borderRadius: '10px',
                    boxShadow: theme.shadows[1],
                    backgroundColor: theme.palette.grey[100],
                  })}>
                  <CardHeader
                    titleTypographyProps={{ variant: 'h6' }}
                    action={
                      <ButtonGroup>
                        <IconButton onClick={() => {
                          setApplying(index);
                          field.setter(masterTemplate[`Memo${index + 1}`]);
                          setTimeout(() => {
                            setApplying(false);
                          }, 1000);
                        }}>
                          <Tooltip title={t('apply')}>
                            {applying === index ? (
                              <CheckIcon color="success" />
                            ) : (
                              <ContentPasteGoIcon />
                            )}
                          </Tooltip>
                        </IconButton>
                        <IconButton onClick={() => {
                          setCopying(index);
                          copyTextToClipboard(masterTemplate[`Memo${index + 1}`]);
                          setTimeout(() => {
                            setCopying(false);
                          }, 1000);
                        }}>
                          <Tooltip title={t('copy')}>
                            {copying === index ? (
                              <CheckIcon color="success" />
                            ) : (
                              <ContentCopyIcon />
                            )}
                          </Tooltip>
                        </IconButton>
                      </ButtonGroup>
                    }
                    title={field.label} />
                  <CardContent>
                    <Typography dangerouslySetInnerHTML={{ __html: masterTemplate[`Memo${index + 1}`] }}></Typography>
                  </CardContent>
                </Card>
              </Box>
            </>
          ))}
        </Box>
      </Popover>
    </>);
}

export default PreviewTemplate;