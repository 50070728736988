import { Box, Slider, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const euro = Intl.NumberFormat('nl-NL', {
  style: 'currency',
  currency: 'EUR',
});

function valuetext(value, t) {
  return `${euro.format(value / 1000000)} ${t('channelconfig.gads.perday')}`;
}

const GoogleAdsBudget = ({ value, fieldId, setFormData }) => {
  const { t } = useTranslation('common');

  return (
    <>
      <Box mt={2}>
        <Typography gutterBottom>{t('channelconfig.gads.budget')}</Typography>
        <Box mt={5} px={4}>
          <Slider
            id={fieldId}
            name={fieldId}
            label={t('channelconfig.gads.budget')}
            defaultValue={0}
            onChange={setFormData}
            value={value}
            step={100000}
            min={0}
            max={10000000}
            valueLabelFormat={(v) => valuetext(v, t)}
            valueLabelDisplay="on"
          />
        </Box>
      </Box>
    </>
  );
};

export default GoogleAdsBudget;
