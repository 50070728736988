import { Accordion, AccordionDetails, AccordionSummary, Box, Typography, Avatar, Stack, Fab, List, Badge, Alert } from "@mui/material";
import { useLocation } from "react-router-dom";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/Add';
import { useContext, useEffect, useRef, useState } from "react";
import { LoadingButton } from "@mui/lab";
import SaveIcon from '@mui/icons-material/Save';
import { useTranslation } from "react-i18next";
import SubFunctionManagement from "./SubFunctionManagement";
import { TokenContext } from "../../context/TokenContext";
import { afasPoster } from "../../hooks/useAfasDataMutation";

/**
 * @typedef {Object} Gladior_ATS_Employer_Functions
 * @property {string} CmId - The employer id
 * @property {string} FuId - The unique identifier for functions
 * @property {string} FunctionTitleNL - The name of the function NL
 * @property {string} FunctionTitleEN - The name of the function EN
 * @property {string} MasterTemplate - JSON string of Function_MasterTemplate
*/

/**
 * @typedef {Object} Subfunction
 * @property {int} Index - The employer id
 * @property {string} Title - Title for the subfunction
 * @property {string} Memo1
 * @property {string} Memo2
 * @property {string} Memo3
 * @property {string} Memo4
*/

const FunctionManagment = ({ func, functionsMutator, config }) => {
  const token = useContext(TokenContext);
  const { t } = useTranslation('common');
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const displayLang = urlParams.get('displayLang') ?? 'nl';
  const [expanded, setExpanded] = useState(false);
  const [subFunctions, setSubFunctions] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [success, setSuccess] = useState(false);
  const formRef = useRef();

  /** @type {Gladior_ATS_Employer_Functions} */
  const atsFunction = func;

  useEffect(() => {
    setSubFunctions(JSON.parse(atsFunction.MasterTemplate ?? '{}')[displayLang] ?? []);
  }, [atsFunction, displayLang])

  const addSubFunction = () => setSubFunctions(s => [...s, { Index: s.length, Title: "", Memo1: "", Memo2: "", Memo3: "", Memo4: "" }]);

  /**
   * Post a subfunction to the endpoint, given a subfunction and mainfunction
   * @param {Subfunction[]} subfunctions - The subfunctions to post
   * @param {Gladior_ATS_Employer_Functions} mainFunction - The main function to post
   * @returns {Promise<object>} - The response of the post request
   */
  async function postSubmission(subfunctions, mainFunction) {
    const arg = { token, body: subfunctions };
    let postData = await afasPoster(`/setmastertemplates/${displayLang}/${mainFunction.CmId}/${mainFunction.FuId}`, { arg });
    console.log(postData);
    setSubmitting(false);
    functionsMutator();
    if (postData) {
      setSuccess(true);
    }
    setTimeout(() => {
      setSuccess(false);
    }, 3000);
    return postData;
  }

  function subFunctionsFilled(subFunctions) {
    return subFunctions.every((sub) => sub.Title !== '');
  }

  async function handleFormSubmission(e) {
    e.preventDefault();
    console.log(subFunctions);
    setSubmitting(true);
    try {
      return await postSubmission(subFunctions, func);
    } catch (error) {
      throw error;
    }
  }

  /** @type {Subfunction[]} */
  subFunctions.map((subFunc, i) => {
    subFunc.Index = i;
    return subFunc;
  });

  return (
    <>
      <Box mb={2} key={`${func.CmId}${func.FuId}`}>
        <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel${atsFunction.FuId}-content`}
            id={`panel${atsFunction.FuId}-header`}
            sx={(theme) => ({
              backgroundColor: 'white',
              boxShadow: theme.shadows[1],
              borderRadius: '10px',
            })}
          >
            <Stack
              direction={'row'}
              justifyContent={'flex-start'}
              gap={4}
              alignItems={'center'}
              useFlexGap={true}
              sx={{
                width: '100%',
                paddingRight: '2rem'
              }}
            >
              <Avatar
                imgProps={{
                  sx: {
                    objectFit: 'contain'
                  }
                }}
                variant="square"
                src="/images/afas-logo.png"
                alt="AFAS logo"
                sx={{
                  width: '4rem',
                }}
              />
              <Badge
                showZero
                badgeContent={subFunctions.length}
                color={
                  subFunctions.length > 0 ?
                    (subFunctionsFilled(subFunctions)
                      ? 'success'
                      : 'error'
                    ) : 'warning'}>
                <Typography variant="h5">{displayLang === 'nl' ? atsFunction.FunctionTitleNL : atsFunction.FunctionTitleEN}</Typography>
                <Typography variant="caption" color="initial" sx={{
                  marginTop: 'auto',
                  marginLeft: '0.5rem',
                  opacity: '.5'
                }}>{atsFunction.FuId}</Typography>
              </Badge>
              <Fab
                color="primary"
                size="small"
                sx={{
                  marginLeft: 'auto',
                  boxShadow: 'unset',
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  addSubFunction();
                  setExpanded(true);
                }}>
                <AddIcon />
              </Fab>
            </Stack>
          </AccordionSummary>
          <AccordionDetails
            sx={(theme) => ({
              backgroundColor: theme.palette.grey[100],
              marginLeft: '2rem',
              borderRadius: '10px',
              boxShadow: theme.shadows[1],
            })}>
            {subFunctions && (
              <form ref={formRef}>
                <List>
                  {subFunctions.map((subFunc, i) => (
                    <SubFunctionManagement config={config} setSubFunctions={setSubFunctions} subFunc={subFunc} key={subFunc.Index} />
                  ))}
                </List>
                <Box mt={2}>
                  <Stack direction={'row'} justifyContent={'flex-end'} alignItems={'center'}>
                    {success && (
                      <Box mr={4}>
                        <Alert severity="success">
                          {t('management.mastertemplate.successsaved')}
                        </Alert>
                      </Box>
                    )}
                    <Box my={2}>
                      <LoadingButton
                        type="submit"
                        variant="contained"
                        loading={submitting}
                        onClick={handleFormSubmission}
                        endIcon={<SaveIcon />}
                        loadingPosition="end"
                      >
                        {t('update')}
                      </LoadingButton>
                    </Box>
                  </Stack>
                </Box>
              </form>
            )}
          </AccordionDetails>
        </Accordion>
      </Box>
    </>
  );
}

export default FunctionManagment;