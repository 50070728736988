import { Box, Button, ButtonGroup, Card, CardContent, Checkbox, FormControlLabel, FormGroup, InputAdornment, LinearProgress, Pagination, Stack, TextField, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import useAfasData from "../hooks/useAfasData";
import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import SearchIcon from '@mui/icons-material/Search';
import FunctionManagment from "../components/management/FunctionManagement";

const Mastertemplate = () => {
  // const token = useContext(TokenContext);
  const { t } = useTranslation('common');
  const navigate = useNavigate();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const lang = urlParams.get('lang') ?? 'nl';
  const displayLang = urlParams.get('displayLang') ?? lang;
  const [currentPage, setCurrentPage] = useState(1);
  const [showOnlySubFunctions, setShowOnlySubFunctions] = useState(false);
  const [filteredFunctions, setFilteredFunctions] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const rowsPerPage = 10;

  const { data: atsConfig, isLoading: loadingAtsConfig } = useAfasData(`/atsconfig`, { language: displayLang });

  const { data: functions, isLoading: loadingFunctions, mutate: functionsMutator } = useAfasData(`/getmastertemplates/${displayLang}`);
  /**
   * @typedef {Object} Gladior_ATS_Employer_Functions
   * @property {string} CmId - The employer id
   * @property {string} FuId - The unique identifier for functions
   * @property {string} FunctionTitleNL - The name of the function NL
   * @property {string} FunctionTitleEN - The name of the function EN
   * @property {string} MasterTemplate - JSON string of Function_MasterTemplate
  */
  /** @type {Gladior_ATS_Employer_Functions[]} */
  const functionsMasterTemplates = functions;

  const handlePageChange = (event, newPage) => setCurrentPage(newPage);

  useEffect(() => {
    urlParams.set('displayLang', lang);
  }, [lang])

  useEffect(() => {
    setCurrentPage(1);
    setFilteredFunctions(functionsMasterTemplates);
  }, [functionsMasterTemplates]);

  const paginatedFunctions = useMemo(
    () => {
      const byPage = (functions = []) => {
        const startIndex = (currentPage - 1) * rowsPerPage;
        const endIndex = startIndex + rowsPerPage;
        return functions.slice(startIndex, endIndex);
      };
      return byPage(filteredFunctions)
    },
    [filteredFunctions, currentPage, rowsPerPage]
  );

  useEffect(() => {
    if (showOnlySubFunctions) {
      setCurrentPage(1);
      setFilteredFunctions((filteredFunctions) => filteredFunctions.filter((func) => func.MasterTemplate && JSON.parse(func.MasterTemplate)[displayLang].length > 0));
    } else {
      setFilteredFunctions(functionsMasterTemplates);
    }
    if (searchTerm) {
      setCurrentPage(1);
      setFilteredFunctions((filteredFunctions) => filteredFunctions.filter((func) => {
        let searchTitles = [];
        searchTitles.push(func.FuId);
        searchTitles.push(displayLang === 'nl' ? (func.FunctionTitleNL || '') : (func.FunctionTitleEN || ''));
        if (func.MasterTemplate) {
          searchTitles.push(...JSON.parse(func.MasterTemplate)[displayLang].map((sub) => sub.Title));
        }
        return searchTitles && searchTitles.length > 0 ? searchTitles.some((title) => title.toLowerCase().includes(searchTerm.toLowerCase())) : false;
      }));
    }
  }, [showOnlySubFunctions, functionsMasterTemplates, displayLang, searchTerm]);

  return (
    <Box>
      <Card
        sx={(theme) => ({
          borderRadius: '10px',
          boxShadow: theme.shadows[1],
          backgroundColor: 'white',
        })}
      >
        <CardContent
          sx={{
            padding: '3.5rem 4.5rem',
          }}
        >
          <Stack direction={'row'} justifyContent="space-between" alignItems="center" mb={4}>
            <Typography variant="h3">
              {t('management.mastertemplate.title')}
            </Typography>
          </Stack>
          <Box mt={4}>
            <Typography mb={1} variant="body1" color="initial">{t('management.mastertemplate.displaylang')}</Typography>
            <ButtonGroup aria-label="Select language">
              <Button
                variant={displayLang === 'nl' ? 'contained' : 'outlined'}
                onClick={() => {
                  urlParams.set('displayLang', 'nl');
                  navigate(
                    {
                      pathname: `/management/mastertemplate/`,
                      search: urlParams.toString(),
                    }
                  );
                }}
                sx={(theme) => ({
                  border: `1px solid ${theme.vars.palette.primary.main}`
                })}>
                <img
                  src="https://flagcdn.com/w40/nl.png"
                  alt="Dutch flag"
                  style={{ width: '24px', height: '16px' }}
                />
              </Button>
              <Button
                variant={displayLang === 'en' ? 'contained' : 'outlined'}
                onClick={() => {
                  urlParams.set('displayLang', 'en');
                  navigate(
                    {
                      pathname: `/management/mastertemplate/`,
                      search: urlParams.toString(),
                    }
                  );
                }}
                sx={(theme) => ({
                  border: `1px solid ${theme.vars.palette.primary.main}`
                })}>
                <img
                  src="https://flagcdn.com/w40/gb.png"
                  alt="English flag"
                  style={{ width: '24px', height: '16px' }}
                />
              </Button>
            </ButtonGroup>
          </Box>
          <Box my={4}>
            <Stack direction="row" spacing={2} useFlexGap={true} justifyContent="space-between">
              <Box>
                <TextField
                  label={t('management.mastertemplate.search')}
                  variant="outlined"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  sx={{
                    width: '300px',
                    maxWidth: '60vw',
                  }}
                  slotProps={{
                    input: {
                      startAdornment: (
                        <InputAdornment position="end">
                          <SearchIcon />
                        </InputAdornment>
                      )
                    }
                  }}
                />
              </Box>
              <Box>
                <FormGroup>
                  <FormControlLabel control={<Checkbox checked={showOnlySubFunctions} onChange={(e) => setShowOnlySubFunctions(e.target.checked)} />} label={t('management.mastertemplate.showonlywithsubfunctions')} />
                </FormGroup>
              </Box>
            </Stack>
          </Box>
          <Box mt={4}>
            {loadingFunctions && (
              <LinearProgress />
            )}
            {!loadingFunctions && (
              <>
                {functionsMasterTemplates && paginatedFunctions.map((func) => (
                  <FunctionManagment config={atsConfig} key={`${func.CmId}${func.FuId}`} func={func} functionsMutator={functionsMutator} />
                ))}
                <Pagination
                  count={!functionsMasterTemplates ? 0 : (filteredFunctions?.length ? Math.ceil(filteredFunctions.length / rowsPerPage) : 0)}
                  page={currentPage}
                  onChange={handlePageChange}
                />
              </>
            )}
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
}

export default Mastertemplate;