import { Dialog } from '@mui/material';
import GoogleAds from '../components/marketing/GoogleAds';
import Teams from '../components/communication/Teams';
import Email from '../components/communication/Email';

const ConfigurationWrapper = ({ dialogOpen, onClose, children }) => {
  return (
    <Dialog
      scroll="paper"
      onClose={onClose}
      open={dialogOpen}
      hideBackdrop={true}
      sx={{ minHeight: '600px', position: 'fixed', width: '100%' }}
      className="channel-dialog"
    >
      {children}
    </Dialog>
  );
};

const ConfigureChannel = ({ channel, vacancy, dataSources, open, setOpen }) => {
  const handleDialogClose = () => {
    setOpen(false);
  };
  switch (channel.name) {
    case 'Google Ads':
      return (
        <ConfigurationWrapper dialogOpen={open} onClose={handleDialogClose}>
          <GoogleAds channel={channel} vacancy={vacancy} onClose={handleDialogClose} />
        </ConfigurationWrapper>
      );
    case 'sms':
      return <div>SMS</div>;
    case 'Microsoft Teams':
      return (
        <ConfigurationWrapper dialogOpen={open} onClose={handleDialogClose}>
          <Teams
            channel={channel}
            vacancy={vacancy}
            onClose={handleDialogClose}
            dataSources={(dataSources ?? []).filter((ds) => ds.dataProvider === 'MICROSOFT_TEAMS_CHAT')}
          ></Teams>
        </ConfigurationWrapper>
      );
    case 'Microsoft Outlook':
      return (
        <ConfigurationWrapper dialogOpen={open} onClose={handleDialogClose}>
          <Email
            channel={channel}
            vacancy={vacancy}
            onClose={handleDialogClose}
            dataSources={(dataSources ?? []).filter((ds) => ds.dataProvider === 'MAIL')}
          ></Email>
        </ConfigurationWrapper>
      );
    default:
      return <></>;
  }
};

export default ConfigureChannel;
