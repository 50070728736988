import { Box, Button, ButtonGroup, Container, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

const Management = () => {
  const { t } = useTranslation('common');
  const navigate = useNavigate();
  let { search, pathname } = useLocation();

  return (
    <>
      <Container maxWidth="xl">
        <Box mb={4}>
          <Box mb={2}>
            <Typography variant="h1">{t('pages.maintitle')}</Typography>
            {/* <Button variant="outlined" color="primary" onClick={() => navigate({ pathname: `/details/`, search: search })}>
            Test knop
          </Button> */}
          </Box>
          <ButtonGroup>
            <Button variant={['/management/', '/management/sources/'].includes(pathname) ? 'contained' : 'outlined'} onClick={() => navigate({ pathname: `/management/sources/`, search: search })}>
              {t('pages.sources')}
            </Button>
            <Button variant={['/management/gptconfig/'].includes(pathname) ? 'contained' : 'outlined'} onClick={() => navigate({ pathname: `/management/gptconfig/`, search: search })}>
              {t('pages.gptconfig')}
            </Button>
            <Button variant={['/management/mastertemplate/'].includes(pathname) ? 'contained' : 'outlined'} onClick={() => navigate({ pathname: `/management/mastertemplate/`, search: search })}>
              {t('pages.mastertemplate')}
            </Button>
          </ButtonGroup>
        </Box>
        <Outlet />
      </Container>
    </>
  );
}

export default Management;