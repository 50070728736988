import { useTranslation } from 'react-i18next';
import StepHeader from './StepHeader';
import {
  Box,
  IconButton,
  Stack,
  Typography,
  Grid,
  Card,
  CardContent,
  FormControlLabel,
  Icon,
} from '@mui/material';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import styled from '@emotion/styled';
import { IOSSwitch } from './Switch';
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import ConfigureChannel from '../containers/ConfigureChannel';
import { useContext, useEffect } from 'react';
import { DatahubContext } from '../context/DatahubContext';
import useDatahubData from '../hooks/useDatahubData';
import { Feed, Settings } from '@mui/icons-material';

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));

const Step3 = ({ sx, vacancy, channels, adchannels, setCustomChannels, selectedCustomChannels }) => {
  const { t } = useTranslation('common');

  const { workspace } = useContext(DatahubContext);
  const { data: dataSources } = useDatahubData(workspace ? '/preferred-data-source' : null, {
    teamId: workspace?.id,
  });
  const { data: customChannels } = useDatahubData(
    dataSources && dataSources?.find((ds) => ds.dataProvider === 'GLADIOR_ATS') ? '/ats-feeds' : null,
    {
      dataSourceId: dataSources?.find((ds) => ds.dataProvider === 'GLADIOR_ATS')?.id,
    },
  );

  useEffect(() => {
    if (customChannels) {
      setCustomChannels(selectedCustomChannels.filter((cc) => customChannels.map((c) => c.key).includes(cc)));
    }
  }, [customChannels]);

  return (
    <>
      <Box {...sx}>
        <StepHeader
          description={t('steps.description.verspreiding')}
          title={t('steps.titles.step_3')}
          stepNumber={3}
        />
        <Box mt={4}>
          <Stack direction="row" alignItems="center" mb={2}>
            <Typography variant="h2" color="initial">
              {t('steps.description.gratiszichtbaar')}
            </Typography>
            <BootstrapTooltip title={t('tooltip.zichtbaarheid')} placement="bottom-start">
              <IconButton sx={{ marginLeft: 2 }}>
                <HelpOutlineOutlinedIcon />
              </IconButton>
            </BootstrapTooltip>
          </Stack>
          <Grid container spacing={4}>
            {channels?.map((channel, index) => (
              <Grid item xs={4} key={index}>
                <Card
                  sx={{
                    borderRadius: '1rem',
                    boxShadow: '10px 10px 30px 0px rgba(0, 0, 0, 0.15)',
                    height: '100%',
                  }}
                  className={Boolean(channel.available) ? 'available' : 'not-available'}
                >
                  <CardContent>
                    <Stack direction="column" justifyContent="space-between">
                      <Box alignSelf="flex-end">
                        <FormControlLabel
                          control={
                            <IOSSwitch
                              disabled={!Boolean(channel.available)}
                              name={channel.key.toLowerCase().replace(/\s/g, '_')}
                              onChange={(e) => channel.setter(e.target.checked)}
                              checked={channel.checked}
                              value={channel.checked}
                            />
                          }
                          variant="standard"
                          sx={{ mr: 0 }}
                        />
                      </Box>
                      <img
                        src={channel.image}
                        alt=""
                        width="120"
                        height="50"
                        style={{ objectFit: 'contain' }}
                      />
                    </Stack>
                  </CardContent>
                </Card>
              </Grid>
            ))}
            <Grid item xs={4}>
              <Card
                sx={{
                  borderRadius: '1rem',
                  boxShadow: '10px 10px 30px 0px rgba(0, 0, 0, 0.15)',
                  height: '100%',
                }}
              >
                <CardContent sx={{ height: '100%' }}>
                  <Stack direction="row" justifyContent="space-between" sx={{ height: '100%' }}>
                    <Typography
                      variant="body1"
                      sx={{ fontSize: '1.25rem', fontWeight: 500, maxWidth: '60%' }}
                      color="initial"
                    >
                      {t('labels.missingchannel')}
                    </Typography>
                    <Box alignSelf="flex-end">
                      <IconButton sx={{ border: '2px solid', fontSize: '.8rem', padding: '4px' }}>
                        <ArrowForwardRoundedIcon />
                      </IconButton>
                    </Box>
                  </Stack>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
        <Box mt={4}>
          <Stack direction="row" alignItems="center" mb={2}>
            <Typography variant="h2" color="initial">
              {t('steps.description.onlineadverteren')}
            </Typography>
            <BootstrapTooltip title={t('tooltip.adverteren')} placement="bottom-start">
              <IconButton sx={{ marginLeft: 2 }}>
                <HelpOutlineOutlinedIcon />
              </IconButton>
            </BootstrapTooltip>
          </Stack>
          <Grid container spacing={4}>
            {adchannels?.map((channel, index) => (
              <Grid item xs={4} key={index}>
                <Card
                  sx={{
                    borderRadius: '1rem',
                    boxShadow: '10px 10px 30px 0px rgba(0, 0, 0, 0.15)',
                    height: '100%',
                  }}
                  className={Boolean(channel.available) ? 'available' : 'not-available'}
                >
                  <CardContent>
                    <Stack direction="column" justifyContent="space-between">
                      <Box alignSelf="flex-end">
                        <FormControlLabel
                          control={
                            <IOSSwitch
                              disabled={!Boolean(channel.available)}
                              name={channel.name.toLowerCase().replace(/\s/g, '_')}
                              onChange={(e) => {
                                channel.setter(e.target.checked);
                                e.target.checked && channel.setOpen && channel.setOpen(true);
                              }}
                              checked={channel.checked}
                              value={channel.checked}
                            />
                          }
                          variant="standard"
                          sx={{ mr: 0 }}
                        />
                        <IconButton
                          disabled={!channel.checked}
                          onClick={() => {
                            channel.setOpen && channel.setOpen(true);
                          }}
                        >
                          <Settings></Settings>
                        </IconButton>
                      </Box>

                      <img
                        src={channel.image}
                        alt=""
                        width="120"
                        height="50"
                        style={{ objectFit: 'contain' }}
                      />
                    </Stack>
                  </CardContent>
                </Card>
              </Grid>
            ))}
            <Grid item xs={4}>
              <Card
                sx={{
                  borderRadius: '1rem',
                  boxShadow: '10px 10px 30px 0px rgba(0, 0, 0, 0.15)',
                  height: '100%',
                }}
              >
                <CardContent sx={{ height: '100%' }}>
                  <Stack direction="row" justifyContent="space-between" sx={{ height: '100%' }}>
                    <Typography
                      variant="body1"
                      sx={{ fontSize: '1.25rem', fontWeight: 500, maxWidth: '60%' }}
                      color="initial"
                    >
                      {t('labels.missingchannel')}
                    </Typography>
                    <Box alignSelf="flex-end">
                      <IconButton sx={{ border: '2px solid', fontSize: '.8rem', padding: '4px' }}>
                        <ArrowForwardRoundedIcon />
                      </IconButton>
                    </Box>
                  </Stack>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
        <Box mt={4}>
          <Stack direction="row" alignItems="center" mb={2}>
            <Typography variant="h2" color="initial">
              {t('steps.description.customChannels')}
            </Typography>
            <BootstrapTooltip title={t('tooltip.custom')} placement="bottom-start">
              <IconButton sx={{ marginLeft: 2 }}>
                <HelpOutlineOutlinedIcon />
              </IconButton>
            </BootstrapTooltip>
          </Stack>
          <Grid container spacing={4}>
            {customChannels?.map((channel, index) => (
              <Grid item xs={4} key={index}>
                <Card
                  sx={{
                    borderRadius: '1rem',
                    boxShadow: '10px 10px 30px 0px rgba(0, 0, 0, 0.15)',
                    height: '100%',
                  }}
                  className={'available'}
                >
                  <CardContent>
                    <Stack direction="column" justifyContent="space-between">
                      <Box alignSelf="flex-end">
                        <FormControlLabel
                          control={
                            <IOSSwitch
                              disabled={false}
                              name={channel.name.toLowerCase().replace(/\s/g, '_')}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setCustomChannels((old) => [...old, channel.key]);
                                } else {
                                  setCustomChannels((old) => old.filter((c) => c !== channel.key));
                                }
                              }}
                              checked={selectedCustomChannels.includes(channel.key)}
                              value={selectedCustomChannels.includes(channel.key)}
                            />
                          }
                          variant="standard"
                          sx={{ mr: 0 }}
                        />
                      </Box>
                      <Stack direction="row" alignItems="center" spacing={4}>
                        {channel?.image ? (
                          <img
                            src={channel.image}
                            alt=""
                            width="100px"
                            height="50px"
                            style={{ objectFit: 'contain' }}
                          />
                        ) : (
                          <Icon
                            sx={{
                              width: '100px',
                              height: '50px',
                              objectFit: 'contain',
                              color: 'black',
                            }}
                          >
                            <Feed
                              color="info"
                              sx={{
                                fontSize: '3rem',
                              }}
                            ></Feed>
                          </Icon>
                        )}
                        <Typography variant="body1" fontWeight={700}>
                          {channel.name}
                        </Typography>
                      </Stack>
                    </Stack>
                  </CardContent>
                </Card>
              </Grid>
            ))}
            <Grid item xs={4}>
              <Card
                sx={{
                  borderRadius: '1rem',
                  boxShadow: '10px 10px 30px 0px rgba(0, 0, 0, 0.15)',
                  height: '100%',
                }}
              >
                <CardContent sx={{ height: '100%' }}>
                  <Stack direction="row" justifyContent="space-between" sx={{ height: '100%' }}>
                    <Typography
                      variant="body1"
                      sx={{ fontSize: '1.25rem', fontWeight: 500, maxWidth: '60%' }}
                      color="initial"
                    >
                      {t('labels.missingchannel')}
                    </Typography>
                    <Box alignSelf="flex-end">
                      <IconButton sx={{ border: '2px solid', fontSize: '.8rem', padding: '4px' }}>
                        <ArrowForwardRoundedIcon />
                      </IconButton>
                    </Box>
                  </Stack>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Box>
      {adchannels?.map(
        (chan) =>
          chan.checked && (
            <ConfigureChannel
              channel={chan}
              key={chan.name}
              vacancy={vacancy}
              open={chan.open}
              setOpen={chan.setOpen}
            />
          ),
      )}
    </>
  );
};

export default Step3;
