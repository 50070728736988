import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import common_nl from './translations/nl/common.json';
import common_en from './translations/en/common.json';

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const lang = urlParams.get('lang') ?? 'nl';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    interpolation: { escapeValue: false }, // React already does escaping
    lng: lang, // language to use
    resources: {
      nl: {
        common: common_nl,
      },
      en: {
        common: common_en,
      },
    },
  });

export default i18n;