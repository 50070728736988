import { useTranslation } from 'react-i18next';
import StepHeader from './StepHeader';
import { Box, Stack, Typography, Grid, Card, CardContent, FormControlLabel, IconButton } from '@mui/material';
import { IOSSwitch } from './Switch';
import ConfigureChannel from '../containers/ConfigureChannel';
import { useContext } from 'react';
import { DatahubContext } from '../context/DatahubContext';
import useDatahubData from '../hooks/useDatahubData';
import { Settings } from '@mui/icons-material';
const Step4 = ({ sx, channels }) => {
  const { t } = useTranslation('common');

  const { workspace } = useContext(DatahubContext);
  const { data: dataSources } = useDatahubData(workspace ? '/data-sources' : null, {
    teamId: workspace?.id,
  });

  return (
    <>
      <Box {...sx}>
        <StepHeader
          description={t('steps.description.voortgang')}
          title={t('steps.titles.step_4')}
          stepNumber={3}
        />
        <Box mt={4}>
          <Grid container spacing={4}>
            {channels.map((channel, index) => (
              <Grid item xs={6} key={index}>
                <Card
                  sx={{
                    borderRadius: '1rem',
                    boxShadow: '10px 10px 30px 0px rgba(0, 0, 0, 0.15)',
                    height: '100%',
                  }}
                >
                  <CardContent>
                    <Stack direction="column" justifyContent="space-between">
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                          alignItems: 'center',
                        }}
                      >
                        <FormControlLabel
                          control={
                            <IOSSwitch
                              name={channel.name.toLowerCase().replace(/\s/g, '_')}
                              onChange={(e) => {
                                channel.setter(e.target.checked);
                                e.target.checked && channel.setOpen && channel.setOpen(true);
                              }}
                              value={channel.checked}
                              checked={channel.checked}
                            />
                          }
                          variant="standard"
                          sx={{ mr: 0 }}
                        />

                        <IconButton
                          disabled={!channel.checked}
                          onClick={() => {
                            channel.setOpen && channel.setOpen(true);
                          }}
                        >
                          <Settings></Settings>
                        </IconButton>
                      </Box>
                      <Stack direction="row" alignItems="center" spacing={4}>
                        <img
                          src={channel.image}
                          alt=""
                          width="70"
                          height="55"
                          style={{ objectFit: 'contain' }}
                        />
                        <Typography variant="body1" fontWeight={700}>
                          {channel.name}
                        </Typography>
                      </Stack>
                    </Stack>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
      {channels?.map(
        (chan) =>
          chan.checked && (
            <ConfigureChannel
              channel={chan}
              key={chan.name}
              dataSources={dataSources}
              open={chan.open}
              setOpen={chan.setOpen}
            />
          ),
      )}
    </>
  );
};

export default Step4;
